import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { attrs: { fluid: "" } },
    [
      _c(
        VRow,
        [
          _c(VCol, [
            _vm._v(
              "\n            코스 이름 : " +
                _vm._s(_vm.courseName) +
                "\n            클래스 이름 : " +
                _vm._s(_vm.clazzName) +
                "\n            랩 이름 : " +
                _vm._s(_vm.labName) +
                "\n        "
            ),
          ]),
        ],
        1
      ),
      _c(
        VRow,
        [
          _c(VCol, [
            _vm._v(
              "\n            랩 시나리오 : " +
                _vm._s(_vm.labScenario) +
                "\n            랩 시간 : " +
                _vm._s(_vm.labTime) +
                "\n            랩 성공 데이터 : " +
                _vm._s(_vm.passData) +
                "\n        "
            ),
          ]),
        ],
        1
      ),
      _c(
        VRow,
        [
          _c(
            VCol,
            [
              _c(
                VBtn,
                {
                  on: {
                    click: function ($event) {
                      return _vm.changeLab1()
                    },
                  },
                },
                [_vm._v("1번 랩")]
              ),
            ],
            1
          ),
          _c(
            VCol,
            [
              _c(
                VBtn,
                {
                  on: {
                    click: function ($event) {
                      return _vm.changeLab2()
                    },
                  },
                },
                [_vm._v("2번 랩")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._l(_vm.labUserList, function (user, index) {
        return _c(VRow, { key: index, attrs: { md: "3" } }, [
          _vm._v(
            "\n\n        " +
              _vm._s(user) +
              "\n        " +
              _vm._s(user.username) +
              "\n        " +
              _vm._s(user.isPass) +
              "\n    "
          ),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }